<template>
  <a-spin :spinning="loading">
    <div class="course-detail-box wrapper">
      <div class="top">
        <a-carousel class="course-slider" :autoplay="true">
          <template
            v-for="(item, index) in toArr(course?.course_image)"
            :key="index"
          >
            <div class="slider-img-wrap">
              <img :src="item" alt="" class="slider-img" />
            </div>
          </template>
        </a-carousel>

        <div class="name flex mt-20">
          <div class="tag" v-if="course?.course_hassignup == 1">学习中</div>
          <div class="font-20 font-bold text-black">
            {{ course?.course_name }}
          </div>
        </div>
        <div class="font-16 mt-10">
          <span class="mr-10">共{{ course?.course_resource_num }}节课</span>
          <span v-if="course?.course_hassignup == 1">
            已学<span
              style="font-weight: bold; color: #1276cb; font-size: 20px"
              >{{ course?.course_record_finish_num }}</span
            >节课
          </span>
        </div>
        <div class="font-16 mt-10 text-gray">
          {{ course?.course_start_time }}&nbsp;&nbsp; — &nbsp;
          {{ course?.course_end_time }}
        </div>
      </div>
      <div class="line"></div>
      <div class="menu flex">
        <div v-for="(item, i) in menu_list" :key="i" class="menu-e">
          <span
            @click="menuClick(item.key, $event)"
            :class="active == item.key ? 'blue' : ''"
            style="cursor: pointer; transition: color 0.2s"
          >
            {{ item.label }}</span
          >
        </div>
        <div class="line-active" id="line"></div>
      </div>
      <div
        class="course_introduction"
        v-if="active == 0"
        v-html="course?.course_introduction"
      ></div>
      <div class="course_resource flex" v-if="active == 1">
        <div
          v-for="(item, i) in course_resource"
          :key="i"
          class="res-e flex flex-between"
          :style="{ marginRight: (i + 1) % 2 == 0 ? '' : '60px' }"
          @click="lookcourse(item.resource_id)"
        >
          <div class="">
            <div class="name font-18 font-bold">{{ item.resource_name }}</div>
            <div class="time font-16 text-gray">
              <span>需学{{ getTime(item.resource_duration) }}</span>
              <span style="margin-left: 20px" v-if="item.course_record">
                已学
                <span style="color: #1276cb; font-weight: 600">
                  {{ getTime(item.course_record.record_duration) }}
                </span>
              </span>
            </div>
          </div>
          <img class="arrow" src="~@/assets/right-arrow.png" />
        </div>
      </div>
      <div
        class="att flex"
        style="flex-wrap: wrap; margin-bottom: 90px"
        v-if="active == 2"
      >
        <div
          v-for="(item, i) in course_ware"
          :key="i"
          class="flex flex-between attachment-item"
          :style="{ marginRight: (i + 1) % 2 == 0 ? '' : '60px' }"
        >
          <div class="attachment-name flex">
            <img src="~@/assets/course_default.png" alt="" />
            <div class="text-ellipsis">{{ item.ware_name }}</div>
          </div>
          <a @click="downs(item.ware_id)">
            <DownloadOutlined />
          </a>
        </div>
      </div>
      <!-- <a-button type="primary" class="btn" @click="open" v-if="course?.course_hassignup == 0">
        报名
      </a-button> -->
      <a-modal
        :footer="null"
        title="考试报名"
        v-model:visible="visible"
        style="width: 500px"
        centered
      >
        <a-form>
          <a-form-item label="姓名" required>
            <a-input
              v-model:value="sendData.signup_name"
              placeholder="请输入姓名"
            />
          </a-form-item>
          <a-form-item label="电话" required>
            <a-input
              v-model:value="sendData.signup_phone"
              placeholder="请输入手机号"
            />
          </a-form-item>
          <a-form-item label="身份证">
            <a-input
              v-model:value="sendData.signup_idcard"
              placeholder="请输入身份证"
            />
          </a-form-item>
        </a-form>
        <div class="modalbtn" @click="insert">报名</div>
      </a-modal>
    </div>
  </a-spin>
</template>
<script setup>
import {
  getCourseData,
  getCourseWareData,
  insertCourseSignupData,
} from "@/api/main.js";
import { useStore } from "vuex";
import { ref, createVNode } from "vue";
import url from "@/utils/url.js";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import { message, Modal, notification } from "ant-design-vue";

import { DownloadOutlined } from "@ant-design/icons-vue";
const route = useRoute();
const visible = ref(false);
let query = decode(route.query?.t).split(",");
const store = useStore();
const loading = ref(true);
const course = ref();
const course_resource = ref();
const course_ware = ref();
const menu_list = ref([
  { label: "课程介绍", key: 0 },
  { label: "课程目录", key: 1 },
  { label: "课件资料", key: 2 },
]);
const sendData = ref({
  course_id: query[0],
  user_id: store.state.user.user_id,
  signup_name: null,
  signup_phone: store.state.user.user_phone,
  signup_idcard: null,
});
const open = () => {
  sendData.value.signup_name = null;
  sendData.value.signup_idcard = null;
  sendData.value.signup_phone = store.state.user.user_phone;
  visible.value = true;
};
const active = ref(0);
const menuClick = (v, e) => {
  active.value = v;
  let D = document.getElementById("line");
  D.style.left = e.target.offsetLeft + 20 + "px";
};
getCourseData({ course_id: query[0], user_id: store.state.user.user_id }).then(
  async (res) => {
    if (res.code == 200) {
      course.value = res.data.course;
      course_resource.value = res.data.course_resource;
      course_ware.value = res.data.course_ware;
      // console.log(res.data);
      if (res.data.course?.course_hassignup == 0) {
        const a = await insertCourseSignupData({
          course_id: query[0],
          user_id: store.state.user.user_id,
        });
      }
    } else {
      message.error(res.msg);
    }
    loading.value = false;
    console.log(loading.value);

  }
).catch(er=>{
  console.log(er);
})
const getTime = (v) => {
  if (v >= 3600) {
    let h = Math.floor(v / 3600);
    let m = Math.floor((v - h * 3600) / 60);
    let s = v - h * 3600 - m * 60;
    let mm = m == 0 ? "" : `${m}分`;
    return `${h}小时${mm}${s}秒`;
  } else if (v < 3600 && v >= 60) {
    let m = Math.floor(v / 60);
    let s = v - m * 60;
    if (s) {
      return `${m}分${s}秒`;
    } else {
      return `${m}分钟`;
    }
  } else {
    return v + "秒";
  }
};
const downs = (ware_id) => {
  getCourseWareData({
    ware_id,
    course_id: query[0],
    user_id: store.state.user.user_id,
  }).then((res) => {
    if (res.code == 200) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.download = res.data.course_ware.ware_name;
      a.href = res.data.course_ware.ware_src;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      message.error(res.msg);
    }
  });
};
const insert = () => {
  if (!sendData.value.signup_name) {
    return message.error("请输入姓名");
  }
  if (!sendData.value.signup_phone) {
    return message.error("请输入手机号");
  }
  insertCourseSignupData(sendData.value).then((res) => {
    loading.value = false;
    if (res.code == 200) {
      visible.value = false;
      notification["success"]({
        message: "报名成功",
        duration: 1,
      });
      getCourseData({
        course_id: query[0],
        user_id: store.state.user.user_id,
      }).then((res) => {
        if (res.code == 200) {
          course.value = res.data.course;
          course_resource.value = res.data.course_resource;
          course_ware.value = res.data.course_ware;
          // console.log(res.data);
        } else {
          message.error(res.msg);
        }
        loading.value = false;
      });
    } else {
      message.error(res.msg);
    }
  });
  visible.value = false;
};
const lookcourse = async (resource_id) => {
  url.navigateTo("/course/courseresource", {
    t: encode(`${resource_id},${course.value.course_id}`),
  });
};
const toArr = (str) => {
  if (str) {
    return str.split(",");
  }
};
</script>
<style scoped lang="less">
.modalbtn {
  width: 300px;
  height: 46px;
  background-color: #1276cb;
  border-radius: 23px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  margin: 50px auto;
}

.course-detail-box {
  .top {
    padding-top: 20px;
    .course-slider {
      width: 1200px;
      height: 540px;
      background-color: #1276cb;
      border-radius: 10px;
      overflow: hidden;
      .slider-img-wrap {
        height: 540px;
        position: relative;
        .slider-img {
          width: 1200px;
          height: 540px;
          // position: absolute;
          // left: 50%;
          // top: 50%;
          // transform: translate(-50%, -50%);
        }
      }
    }
    .name {
      .tag {
        width: 71px;
        height: 24px;
        line-height: 24px;
        background-color: #1276cb;
        border-radius: 12px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin-top: 30px;
  }
  .menu {
    width: 1200px;
    position: relative;
    margin: 50px 0;
    .blue {
      color: #1276cb;
    }
    .menu-e {
      transition: color 0.2s;
      width: 400px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      color: #212531;
    }
    .line-active {
      width: 40px;
      height: 2px;
      background-color: #1276cb;
      border-radius: 1px;
      position: absolute;
      left: 180px;
      bottom: -20px;
      transition: left 0.3s;
    }
  }
  .course_introduction {
    margin-bottom: 90px;
    width: 1200px;
    img {
      max-width: 1200px !important;
    }
  }
  .course_resource {
    width: 1200px;
    margin-bottom: 90px;
    flex-wrap: wrap;
    .res-e {
      width: 570px;
      height: 90px;
      border-bottom: solid 1px #dfdfdf;
      cursor: pointer;
      .arrow {
        height: 16px;
      }
    }
  }
  .attachment-item {
    width: 570px;
    height: 81px;
    background-color: #f3f5f8;
    border-radius: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .attachment-name {
      color: #212531;
      font-size: 18px;
      img {
        margin-right: 10px;
      }
      .text-ellipsis {
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
